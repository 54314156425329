<template>
  <main class="signin">
    <figure>
      <img src="/images/new/signin.png" class="-pc" />
      <img src="/images/new/signin_m.png" class="-mobile" />
    </figure>
    <section class="main__section">
      <Alert
        v-model:alert="alert"
        v-if="!!alert.content"
        :isConfirm="true"
        @update:clear="
          () => {
            alert.content = '';
          }
        "
      />

      <Form v-slot="{ errors }" class="input-box" @submit="login">
        <h2 class="-bold">로그인하기</h2>
        <p class="-regular">
          진료시간을 선택해 온라인대면진료를 받을 수 있고,<br class="-pc" />
          필요시 처방전을 미리 지정한 약국으로 보내주는 서비스입니다.
        </p>
        <Field
          v-slot="{ field }"
          v-model="value.username"
          name="username"
          rules="required|email"
        >
          <label for="username" class="-invisibility">
            {{ t("email") }}
          </label>
          <InputText
            id="username"
            type="text"
            v-model="field.value"
            v-bind="field"
            :class="{ 'p-invalid': errors.username }"
            placeholder="이메일주소를 입력해 주세요."
            aria-placeholder="이메일주소를 입력해 주세요."
          />
        </Field>
        <Field
          v-slot="{ field }"
          v-model="value.password"
          name="password"
          rules="required"
          ><label for="password" class="-invisibility">
            {{ t("password") }}
          </label>
          <InputText
            id="password"
            type="password"
            v-model="field.value"
            v-bind="field"
            :class="{ 'p-invalid': errors.password }"
            placeholder="비밀번호를 입력해 주세요."
            aria-placeholder="비밀번호를 입력해 주세요."
          />
        </Field>
        <span class="input-box__action">
          <Button class="-button -royalBlue" type="submit">{{
            t("signin")
          }}</Button>
        </span>
        <div class="input-box__options">
          <div class="remember">
            <Checkbox id="rememberMe" v-model="isRemember" :binary="true" />
            <label for="rememberMe" class="-bold">로그인 유지</label>
          </div>
          <router-link
            class="-link -bold"
            :to="{ name: RouterName.FORGOT_PASSWORD }"
          >
            {{ t("forgotPassword") }}</router-link
          >
          <router-link class="-link -bold" :to="{ name: RouterName.SIGNUP }">{{
            t("signup")
          }}</router-link>
        </div>
        <Specifications />
        <!-- <div class="siginup-wrapped -medium">
          아직 HESEL 회원이 아니세요?
          <router-link class="-link" :to="{ name: RouterName.SIGNUP }">{{
            t("signup")
          }}</router-link>
        </div> -->
      </Form>
    </section>
  </main>
</template>
<script lang="ts">
import { defineComponent, reactive, ref, Ref } from "vue";
import InputText from "@/components/primevueCustom/InputText.vue";
import Button from "primevue/button";
import { AuthActionTypes } from "@/store/modules/auth/actions";
import { AuthMutationTypes } from "@/store/modules/auth/mutations";
import { useStore } from "@/store";
import router from "@/router";
import { IAlert, RouterName } from "@/models/common";
import { Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { messageType } from "@/models/message";
import Specifications from "@/components/common/Specifications.vue";
import Checkbox from "primevue/checkbox";

export default defineComponent({
  name: "Signin",
  setup() {
    const store = useStore();
    const value = reactive({
      username: "",
      password: "",
    });

    const { t } = useI18n({ useScope: "global" });
    const { t: l } = useI18n();

    const alert: Ref<IAlert> = ref({
      type: messageType.ERROR,
      content: "",
    });

    const isRemember = ref(false);

    return { value, store, alert, t, l, RouterName, isRemember };
  },

  methods: {
    async login() {
      try {
        this.store.commit(AuthMutationTypes.SET_TOKEN, "");

        const res = await this.store.dispatch(AuthActionTypes.GET_TOKEN, {
          email: this.value.username,
          password: this.value.password,
          rememberMe: this.isRemember,
        });

        if (res) {
          this.alert = {
            title: "로그인 오류",
            content: this.t(res),
            type: messageType.ERROR,
          };
        } else {
          await this.store.dispatch(AuthActionTypes.GET_ACCOUNT_INFO);
          const redirect =
            router.currentRoute.value.query?.redirect?.toString() ?? "/";

          router.push({ path: redirect });
        }
      } catch (error) {
        console.error("AuthActionTypes.GET_TOKEN", error);
      }
    },
  },
  components: {
    InputText,
    Button,
    Field,
    Form,
    Specifications,
    Checkbox,
  },
});
</script>
<style lang="scss" scoped>
@import "@/styles/mixin.scss";
main {
  background-color: white;
  display: flex;
  height: 100vh;
  margin: 0;
  min-width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 0;
  figure {
    flex: 1;
    max-width: 940px;
    img {
      max-width: 100%;
      height: 100%;
    }
  }
  @include mobile {
    flex-direction: column;
    figure {
      flex: none;
      max-width: none;
      width: 100%;
      img {
        width: 100%;
      }
    }
    .signin {
      flex: none;
      padding: 30px 40px;
      margin: 0;
    }
  }
}
.signin {
  flex: 1;
  display: flex;
  align-items: center;
  height: 100vh;
  @include pc {
    overflow: hidden;
  }

  .main__section {
    padding: 0 40px;
  }
  form {
    width: 380px;
    margin-bottom: 30px;
    @include mobile {
      width: auto;
    }
    > h2 {
      text-align: left;
      @include mobile {
        font-size: 20px;
      }
    }
    > p {
      font-size: 14px;
      line-height: 170%;
      color: $Grey3;
      @include mobile {
        font-size: 15px;
        word-break: keep-all;
      }
    }
    > label {
      margin: 0;
      height: 30px;
      &[for="password"] {
        height: 20px;
      }
      @include mobile {
        height: 20px;
        &[for="password"] {
          height: 16px;
        }
      }
    }

    input {
      margin: 0;
      &#password {
        margin-bottom: 20px;
        @include mobile {
          margin-bottom: 16px;
        }
      }
      @include mobile {
        margin: 0;
        padding: 13px 16px;
      }
    }
    .input-box__action {
      display: block;
      margin-bottom: 20px;
    }
    .input-box__options {
      margin-bottom: 40px;
      height: 24px;
      text-align: right;
      display: flex;
      justify-content: space-between;
      a {
        font-size: 15px;
        line-height: 160%;
        color: $Grey3;
      }
      .remember {
        display: flex;
        align-items: center;
        label {
          margin: 0;
          font-size: 15px;
        }
      }
      @include mobile {
        margin-bottom: 24px;
        a {
          font-size: 15px;
        }
      }
    }
    .siginup-wrapped {
      display: block;
      margin-top: 40px;
      padding: 8px;
      background-color: #fafbfe;
      color: $Grey3;
      font-size: 12px;
      line-height: 160%;
      text-align: center;
      a {
        margin-left: 4px;
        color: $DarkGrey;
      }
    }
  }
}
#username,
#password {
  border: 1px solid $Grey1;
  color: $Grey2;
}
.signin form button[type="submit"] {
  padding: 0%;
  height: 54px;
  @include mobile {
    height: 46px;
  }
}
</style>
<i18n>
{
  "en": {
    "welcome":"welcome"
  },
  "ja": {
    "welcome":"welcome"
  },
  "ko":{
    "welcome":"서비스를 이용하시기 위해서는 로그인해주시기 바랍니다."
  }
}
</i18n>