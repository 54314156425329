
import { defineComponent, reactive, ref, Ref } from "vue";
import InputText from "@/components/primevueCustom/InputText.vue";
import Button from "primevue/button";
import { AuthActionTypes } from "@/store/modules/auth/actions";
import { AuthMutationTypes } from "@/store/modules/auth/mutations";
import { useStore } from "@/store";
import router from "@/router";
import { IAlert, RouterName } from "@/models/common";
import { Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { messageType } from "@/models/message";
import Specifications from "@/components/common/Specifications.vue";
import Checkbox from "primevue/checkbox";

export default defineComponent({
  name: "Signin",
  setup() {
    const store = useStore();
    const value = reactive({
      username: "",
      password: "",
    });

    const { t } = useI18n({ useScope: "global" });
    const { t: l } = useI18n();

    const alert: Ref<IAlert> = ref({
      type: messageType.ERROR,
      content: "",
    });

    const isRemember = ref(false);

    return { value, store, alert, t, l, RouterName, isRemember };
  },

  methods: {
    async login() {
      try {
        this.store.commit(AuthMutationTypes.SET_TOKEN, "");

        const res = await this.store.dispatch(AuthActionTypes.GET_TOKEN, {
          email: this.value.username,
          password: this.value.password,
          rememberMe: this.isRemember,
        });

        if (res) {
          this.alert = {
            title: "로그인 오류",
            content: this.t(res),
            type: messageType.ERROR,
          };
        } else {
          await this.store.dispatch(AuthActionTypes.GET_ACCOUNT_INFO);
          const redirect =
            router.currentRoute.value.query?.redirect?.toString() ?? "/";

          router.push({ path: redirect });
        }
      } catch (error) {
        console.error("AuthActionTypes.GET_TOKEN", error);
      }
    },
  },
  components: {
    InputText,
    Button,
    Field,
    Form,
    Specifications,
    Checkbox,
  },
});
